.news-content{
	
	.news-section{
		
		.btn{
			margin: 0 0 20px 15px;
			background: none;
			font-family: 'MuseoSans';
			font-weight: 700;
			font-size: 15px;
			padding: 7px 0;
			width: 150px;
			border-radius: 20px;
			border: 2px solid #d3b47f;
			color: #d3b47f;
			letter-spacing: 1px;
			&:hover{
				background-color: #d3b47f;
				color: #ffffff;
			}
			&:focus{
				outline: none;
			}
		}
		
		
		.main-news{
			.news-top-image{
			}
			h2{
				font-size: 20px;
				/*font-family: 'MuseoSlab';*/
				font-family: 'MuseoSans';
				font-weight: 700;
				letter-spacing: 1px;
				padding-left: 15px;
				margin-top: 10px;
				margin-bottom: 15px;
			}
			p{
				padding-left: 15px;
				margin-bottom: 20px;
				font-family: 'MuseoSans';
				font-weight: 700;
				font-size: 14px;
			}
		}
		.news-group{
			padding: 0 5px;
			.news-item-frame{
				padding: 0 10px;
				.news-group-item{
					.news-gr-it-img{
						margin-bottom: 20px;
					}
					h2{
						font-size: 16px;
						padding-left: 15px;
						margin-bottom: 20px;
						font-family: 'MuseoSans';
						font-weight: 700;
						color: black; 
					}
				}
			}
		}
	}
	.news-aside{
		background-color: #d3b47f;
		color: #ffffff;
		padding-top: 50px;
		padding-bottom: 150px;
		.news-aside-item{
			border-bottom: 2px solid #ffffff;
			h2{
				font-size: 16px;
				font-family: 'MuseoSans';
				font-weight: 700;
				padding-top: 20px;
                                color: white;
			}
			.news-aside-time{
				font-family: 'MuseoSans';
				font-weight: 700;
				font-size: 10px;
				padding-bottom: 5px;
                    }
                    a:hover {
                        text-decoration: none;
                    }
		}
	}
}

@media only screen and (max-width : 1024px) {
    .news-aside {
        display: none;
    }
    
    .news-content {
      margin-top: 50px;
    }
    
    .news-section {
        width: 100%;
    }
}

@media only screen and (max-width : 414px) {
    .news-item-frame{
        width: 100%;
    }
    
    .news-item-frame{
        padding: 0;
        margin-right: 0;
    }
}




