#apartments{
	.hero-banner{
		background-image: url('../images/stanovi-parallax.jpg');
	}

	.content{
		
		.nav-pills{
			.tab-content{
				h3{
					color: #d3b47f;
					padding-left: 20px;
				}
			}
			
			li{
				a{
					padding: 10px 172px 10px 20px;
					border-radius: 50px;
					border: 2px solid #d3b47f;
					color: #d3b47f;

					&:hover{
						background-color: #d3b47f;
						color: white;
					}
				}

				&.active{
					a{
						background-color: #d3b47f;
						color: white;
					}
				}
			}
		}

		.nav-tabs{
			padding-top: 20px;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 2px solid #d3b47f;

			li{

				&.active{
					a{
						background-color: #f4efe6;
						box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
						-ms-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
						-moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
						-webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
					}
				}

				a{
					padding: 10px 20px;
					border-radius: 0;

					img{
					    max-width: 100%;
					}

					&:hover{
						background-color: #f4efe6;
						cursor: pointer;

						box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
						-ms-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
						-moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
						-webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.35);
					}
				}
			}
		}

		.apartment-slider{
			margin-top: 20px;
			margin-bottom: 20px;

			.slick-dots{
				bottom: 15px;
			}
		}

		.separator{
			border: 1px solid #d3b47f;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		.apartments{
			.table{
				&.rooms{
					tr{
						td{
							border: none;
						}
						&:first-child{
							th{
								background-color: #d3b47f;
								color: #ffffff;
							}
						}

						&:last-child{
							td{
								background-color: #d3b47f;
								color: #ffffff;
							}
						}

						td:first-child{
							padding-left: 20px;
						}
						td:nth-child(2){
							text-align: left;
						}
					}
				}
			}
		}
	}
	
	h3{
		color: #d3b47f;
	}

	table.equipment{
		tr{
			td{
				border-color: #d3b47f;
				&:first-child{
					font-weight: 700;
				}
			}

			border-bottom: 1px solid #d3b47f;
			border-top: none;
		}
	}
}


@media only screen and (max-width : 1024px) {
    
    #apartments{
	.hero-banner{
		background-position: center center;
                
	}
    }
}
