#sell{
    .hero-banner{
        background-image: url('../images/sell-parallax.jpg');
    }

    .navbar{
        margin-bottom: 0;
    }

    .first-content{
        padding-top: 50px;
        padding-bottom: 50px;
    }


    .form{
        
       .error {
            color: red;
        }
        
        label {
            font-size: 12px;
         }
         
        .container {
          padding: 50px 0;
        }
        
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
        -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
        -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
        -ms-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.23);
        
        padding: 20px 0px;
        
        input {
            border: 1px solid #e2c086;
            border-radius: 5em;
            padding: .2em;
            width: 100%;
            margin-bottom: 15px;
        }
        input[type=submit] {
            width: 100px;
            background: #e2c086;
            color: white;
            margin-bottom: 15px;
        }
        
        textarea {
            display: block !important;
            height: 7.8em;
            width: 100%;
            border: 1px solid #e2c086;
            border-radius: 1em;
            padding: .5em;
            margin-bottom: 12px;
        }

        .form-text{
            color: #e2c086;
            letter-spacing: 1px;
            margin-bottom: 30px;
            text-decoration: underline;
            
            span{
                display:inline-block;
                border-bottom: 2px solid #e2c086;
                padding-bottom: 10px;
            }
        }
    }
    .map{
        padding-top: 50px;
        .sell-map-logo {
            width: 93px;
            margin-bottom: 15px;
        }
        
        a{
            color: #e2c086;
            text-decoration: none;
        }

        .info-text {
            margin: 2em 0;
            font-size: 14px;
        }

        .content-logo {
            margin: 2em 0;
        }

        span {
            padding-top: 10px;
            font-size: 16px;
            line-height: 10px;
            display: block;
        }
        
        .span-color {
            color: #e2c086;
        }
        p{
            line-height: .5em;
        }
        .address {
            margin-top: 25px;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width : 768px) { 
    footer {
        .container{
             background-color: #d3b47f;
             width: 100%;
        }
        .logo{
            margin-top: 0;
        }
        .contact {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width : 414px) {
    
    .first-content{
        .content-logo{
            width: 100%;
            margin-bottom: 2em;
        }
        .info-text {
            width: 100%;
        }
    }
    
    .form-text {
        padding: 1em;
    }
    
    #sell .form input{
      margin-bottom: .6em;
      width: 100%;
    }
    
    #sell .form textarea {
        margin-bottom: .5em;
    }
    
    #sell .form input[type=submit] {
        display: block;
    }
    
   #sell .map {
        img {
            width: 100%;
            margin-bottom: 2em;
        }
        .sell-map-logo {
           width: 100%;
        }
    }
}