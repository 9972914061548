*{
	margin: 0;
	padding: 0;
}
html,
body{
	font-weight: 500;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 200px;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
     background-color: #2c7752;
}

/*.navbar-nav > li > a {
    line-height: 23px;
}*/

header{
	.logo{
		padding-top: 40px;
		padding-bottom: 40px;
		text-align: center;

		img{
			width:100%;
			max-width: 285px;

		}
	}

	.info-header{
			display: -webkit-box;
			display: -webkit-flex;
			display: flex;
			-webkit-box-align: center;
			-webkit-align-items: center;
			align-items: center;

			-webkit-box-pack: center;
			-webkit-justify-content: center;
			justify-content: center;

			font-size: 16px;

		.language{
			visibility: hidden;
			font-family: 'MuseoSlab';
			font-weight: 500;

			padding-bottom: 8px;

			-webkit-align-self: flex-end;
			align-self: flex-end;

			a{
				color: black;

				&.active{
					color: $green-menu;
					font-weight: 900;
				}
			}
		}
		.contact{
			font-size: 14px;
			font-family: 'MuseoSans';
			font-weight: 500;

			color: $green-menu;
			padding-bottom: 8px;

			-webkit-align-self: flex-end;
			align-self: flex-end;

			a{
				color: $green-menu;
				text-decoration: none;
			}
		}
	}
}

nav{
	font-family: 'MuseoSlab';
	font-weight: 500;

	box-shadow: 0px 2px 13px 0px rgba(25, 25, 25, 0.46); 
	-webkit-box-shadow: 0px 2px 13px 0px rgba(25, 25, 25, 0.46); 
	-moz-shadow: 0px 2px 13px 0px rgba(25, 25, 25, 0.46); 
	-ms-box-shadow: 0px 2px 13px 0px rgba(25, 25, 25, 0.46); 

	&.navbar{
		border: none !important;
	}
	
	a{
		font-size: 16px;
	}
	
	
	ul.social{
		min-height: $navbar-height;
		
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;

		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;

		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		
		li{
			-webkit-box-flex: 1;
			-webkit-flex: 1;
			flex: 1;
			
			a{
				color: $green-menu !important;
				padding: 10px 10px;
				
				&:hover,
				&:active,
				&:focus{
					background: none !important;
					color: $green-menu !important;
				}

				i{
					border-radius: 50%;
					width: 24px;
					height: 24px;
					text-align: center;
					padding: 4px 3px;
					background-color: #FFFFFF;
				}
			}
			
			
		}
	}
}

.hero-banner{
	background-image: url('../images/home-parallax.jpg');
	height: 500px;
	background-position: 0% 0%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
}


#about,
#info{
	padding-top: 50px;
	padding-bottom: 50px;
	h1{
		font-size: 22px;
		font-family: 'MuseoSlab';
		font-weight: 700;
		color: #d3b47f;
	}

	.content{
		font-size: 17px;
		font-family: 'MuseoSans';
		font-weight: 300;
	}
}

.slick-dotted.slick-slider{
		margin-bottom: 0;
	}

.slide-2{
	.slide {
	  position: relative;
	}
	.caption {
		position: absolute;
		top: 120px;
		padding: 15px;
		min-height: 38px;
		
		color: white;

		z-index: 50;

		h2{
			font-size: 22px;
			font-family: 'MuseoSlab';
			font-weight: 700;
			color: white;
			text-transform: uppercase;
		}

		.description{
			font-size: 16px;
			font-family: 'MuseoSans';
			font-weight: 500;
			color: white;
			margin-bottom: 25px;
		}

		.cta{
			.btn{
				font-size: 16px;
				font-family: 'MuseoSans';
				font-weight: 500;
				color: white;
				border: 1px solid white;
				padding-left: 15px;
				padding-right: 15px;

				&:hover{
					background-color: white;
					color: black;
				}
			}
		}
	}

}

.dots-1,
.dots-2{
	position: absolute;
	width: 100%;
	.slick-dots{
		top: -50px;
		position: relative;
		z-index: 999;

		li{
			button{
				&:before{
					color: white;
					opacity: 1;	
				}
				
			}

			&.slick-active{
				button{
					&:before{
						color: #d3b47f;
						opacity: 1;
					}
				}
			}
		}
	}
}

#map{
	padding-top: 50px;

	.map,
	.newsletter{
		padding: 0;
		img{
			width: 100%;
			height: 398px;
		}

		.content{
			font-size: 22px;
			font-family: 'MuseoSlab';
			font-weight: 700;

			position: absolute;
			top: 120px;
			left: 30px;
			color: white;

			.btn{
				font-size: 22px;
				font-family: 'MuseoSans';
				font-weight: 500;
				color: white;
				border: 1px solid white;
				padding-left: 45px;
				padding-right: 45px;

				&:hover{
					background-color: white;
					color: black;
				}
			}
		}
	}
}

#scroll-top{
	position: fixed;
	bottom: 165px;
	right: 20px;
	background-color: #d3b47f;
	color: white;
	cursor: pointer;
	font-size: 25px;
	width: 35px;
	text-align: center;
	border-radius: 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
}

@media only screen and(max-width : 768px) {
    header{
        .info-header{
            display: block;
            
            .language {
                display: none;
            }
        }
        
    }
    #scroll-top{
        bottom: 30px;
        border: 2px solid white;
        z-index: 99;
    }
    
    .social,
    .contact{
        text-align: left;
    }
    
    .dots-1 .slick-dots,
    .dots-2 .slick-dots {
        top: -25px;
    }
    
    footer .nav{
        padding-left: 15px;
        
        ul{
            text-align: center;
        }
    }
}

footer{
	background-color: #d3b47f;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 170px;
	/*
	background-color: #d3b47f;
	min-height: 165px;
	margin-top: 50px;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 165px;
	*/
	
	.nav{
		margin-top: 24px;

		ul{
			li{
				font-size: 14px;
				font-family: 'MuseoSlab';
				font-weight: 500;
				list-style: none;

				color: white;

				a{
					color: white;
					text-decoration: none;
				}
			}
		}
	}

	.logo{
		margin-top: 65px;
	}

	.social{
		margin-top: 22px;

		li{
			a{
				color: #d3b47f;
				text-decoration: none;

				i{
					border-radius: 50%;
					width: 24px;
					height: 24px;
					text-align: center;
					padding: 4px 3px;
					background-color: #FFFFFF;
				}
			}
		}
	}

	.contact{
		font-size: 14px;
		font-family: 'MuseoSans';
		font-weight: 500;

		margin-top: 50px;

		color: white;

		a{
			color: white;
			text-decoration: none;
		}

	}

}

#newsletter-modal{
     input[type="submit"] {
        background: #e2c086 none repeat scroll 0 0;
        color: white;
        margin-bottom: 15px;
        width: 100px;
        border: 1px solid #e2c086;
        border-radius: 5em;
        padding: 0.2em;
    }
    
    input {
        border: 1px solid #e2c086;
        border-radius: 5em;
        margin-bottom: 15px;
        display: block;
        padding: 0.2em;
        padding: 0.3em;
        width: 100%;
    }
}

@media only screen and (max-width : 1024px) {
    .hero-banner {
        background-position: center center;
        height: 500px;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px)  {
    .nav > li > a {
       padding: 20px 20px;
    }
}

@media only screen and (max-width : 768px) {
    .hero-banner {
        background-position: center center;
        height: 200px;
    }
}

@media only screen and (max-width : 414px) {
    nav ul.social li {
        flex: none;
    }
    
    .slide-2 .caption {
        top: 0;
    }
    
    #map .map img, #map .newsletter img {
     width: 100%;
     height: auto;
    }
}