@font-face {
  font-family: 'MuseoSans';
  src:  url('#{$font-path}/MuseoSans-300.eot?#iefix') format('embedded-opentype'),
  		url('#{$font-path}/MuseoSans-300.otf')  format('opentype'),
	    url('#{$font-path}/MuseoSans-300.woff') format('woff'),
	    url('#{$font-path}/MuseoSans-300.ttf')  format('truetype'),
	    url('#{$font-path}/MuseoSans-300.svg#MuseoSans-300') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src:  url('#{$font-path}/MuseoSans-500.eot?#iefix') format('embedded-opentype'),
  		url('#{$font-path}/MuseoSans-500.otf')  format('opentype'),
	    url('#{$font-path}/MuseoSans-500.woff') format('woff'),
	    url('#{$font-path}/MuseoSans-500.ttf')  format('truetype'),
	    url('#{$font-path}/MuseoSans-500.svg#MuseoSans-500') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSlab';
  src:  url('#{$font-path}/MuseoSlab-300.eot?#iefix') format('embedded-opentype'),
  		url('#{$font-path}/MuseoSlab-300.otf')  format('opentype'),
	    url('#{$font-path}/MuseoSlab-300.woff') format('woff'),
	    url('#{$font-path}/MuseoSlab-300.ttf')  format('truetype'),
	    url('#{$font-path}/MuseoSlab-300.svg#MuseoSlab-300') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSlab';
  src:  url('#{$font-path}/MuseoSlab-500.eot?#iefix') format('embedded-opentype'),
  		url('#{$font-path}/MuseoSlab-500.otf')  format('opentype'),
	    url('#{$font-path}/MuseoSlab-500.woff') format('woff'),
	    url('#{$font-path}/MuseoSlab-500.ttf')  format('truetype'),
	    url('#{$font-path}/MuseoSlab-500.svg#MuseoSlab-500') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSlab';
  src: 	url('#{$font-path}/MuseoSlab-700.eot?#iefix') format('embedded-opentype'),
  		url('#{$font-path}/MuseoSlab-700.otf')  format('opentype'),
	    url('#{$font-path}/MuseoSlab-700.woff') format('woff'),
	    url('#{$font-path}/MuseoSlab-700.ttf')  format('truetype'),
	    url('#{$font-path}/MuseoSlab-700.svg#MuseoSlab-700') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSlab';
  src: 	url('#{$font-path}/MuseoSlab-900.eot?#iefix') format('embedded-opentype'),
  		url('#{$font-path}/MuseoSlab-900.otf')  format('opentype'),
	    url('#{$font-path}/MuseoSlab-900.woff') format('woff'),
	    url('#{$font-path}/MuseoSlab-900.ttf')  format('truetype'),
	    url('#{$font-path}/MuseoSlab-900.svg#MuseoSlab-900') format('svg');
  font-weight: 900;
  font-style: normal;
}
