$font-path: "../fonts/museo" !default;

$font-family-base: 'MuseoSans';

$navbar-height: 75px !default;
$green-menu: #2c7752;
$font-size-base: 16px;


$navbar-default-bg: #2c7752;
$navbar-border-radius: none;
$navbar-default-border: none;
$navbar-default-link-color:                #FFF;
$navbar-default-link-hover-color:          #FFF;
$navbar-default-link-hover-bg:             #e2c086;
$navbar-default-link-active-color:         #FFF;
$navbar-default-link-active-bg: 		   #e2c086;
$nav-link-padding: 						   10px 50px;

$navbar-margin-bottom: 0px;


$btn-default-color: white;
$btn-default-bg: #d3b47f;
$btn-default-border: #d3b47f;
$btn-border-radius-base: 60px;

$nav-tabs-active-link-hover-border-color: transparent;
$nav-tabs-border-color: transparent;

$slick-dot-color: white !default;
$slick-dot-color-active: #d3b47f !default;

$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;