.investor{
	padding-top: 50px;
	
	button{
		margin: 0;
		background: none;
		font-family: 'MuseoSans';
		font-weight: 700;
		font-size: 15px;
		padding: 7px 12px;
		min-width: 150px;
		border-radius: 20px;
		border: 2px solid #d3b47f;
		color: #d3b47f;
		letter-spacing: 1px;
	}
	button:hover{
		background-color: #d3b47f;
		color: #ffffff;
	}
	button:focus{
		outline: none;
	}
	
	.investor-image{
		margin-top: 100px;
		img{
		}
	}
	.investor-text{
		
		p{
			margin-bottom: 20px;
			font-family: 'MuseoSans';
			font-weight: 500;
			font-size: 16px;
		}
	}
}

@media only screen and (max-width : 414px) {
    .investor {
        .investor-image {
            margin-top: 0;
            margin-bottom: 50px;
        }
        
        .investor-image,
        .investor-text
        {
            width: 100%
        }
    }
}
