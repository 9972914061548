#location{
	.location-map{
		
	}

	.location-text{
		padding-top: 50px;
		h1{
			font-size: 20px;
			font-family: 'MuseoSlab';
			font-weight: 700;
			letter-spacing: 1px;
			text-align: center;
			color: #d3b47f;
			margin-bottom: 50px;
		}
		.nearest-object{
			white-space: nowrap;
			text-align:center;
			margin-bottom: 50px;
			.near-obj-logo{
				height: 47px;
				img{
					position: relative;
	  				top: 50%;
	  				-webkit-transform: translateY(-50%);
	 				-ms-transform: translateY(-50%);
	  				transform: translateY(-50%);
				}
			}
			.near-obj-text{
				font-weight: 700;
				padding: 5px 0;
			}
			.near-obj-time{
				position: relative;
				span.walker{
					display: inline-block;
					background: url('../images/location/walker.png') center center no-repeat;
					width: 21px;
					height: 32px;
					vertical-align: middle;
				}
			}
		}
		p{
			margin-bottom: 20px;
                        font-weight: 300;
		}

	}
}

@media only screen and (max-width : 414px) {
    #location .location-text .nearest-object{
        width: 50%;
    }
}